<template>
	<v-container
		id="userDetail"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3">
					<card-heading
						class=""
						color="secondary"
						:loading="loading"
						loadingTitle="Loading..."
						:title="userName">
						<template v-slot:prepend>
							<div @click="r()">
								<v-icon
									:style="`transform: rotate(${Math.floor(turn / 5) * 360}deg)`"
									v-if="adminUser"
									:key="adminUser"
									color="warning"
									large>
									mdi-crown
								</v-icon>
							</div>
						</template>
						<template v-slot:default>
							<div class="d-flex"></div>
						</template>
					</card-heading>
					<v-card-text>
						<v-list-item>
							<v-list-item-content class="text-body-1">
								Email:
							</v-list-item-content>
							<v-list-item-content
								v-if="!loading"
								class="text-body-2 align-end">
								{{ _userDetail?.data?.email }}
							</v-list-item-content>
							<v-list-item-content
								v-else
								class="text-body-2 align-end">
								Loading email...
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="hasAnyManagement">
							<v-list-item-content class="text-body-1">
								Groups:
							</v-list-item-content>
							<v-list-item-content
								v-if="!loading"
								class="text-body-2 align-end">
								<v-chip-group
									@change="handleChipSelect"
									v-model="selectedGroup"
									active-class="success">
									<v-chip
										style="max-width: fit-content"
										v-for="item in _userDetail.data.groups"
										:key="item"
										:value="item">
										{{ item }}
									</v-chip>
								</v-chip-group>
							</v-list-item-content>
							<v-list-item-content
								v-else
								class="text-body-2 align-end">
								Loading groups...
							</v-list-item-content>
						</v-list-item>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col
				cols="12"
				md="4">
				<v-card
					:height="computedTreeHeight"
					class="v-card--material pa-3 px-5 py-3 d-flex flex-column">
					<card-heading
						color="secondary"
						title="Casinos">
						<template #title="{ title }">{{ title }}</template>
						<template #append>
							<v-spacer></v-spacer>
							<v-text-field
								dense
								:light="!$vuetify.theme.dark"
								hide-details
								label="Search"
								clearable
								v-model="casinoSearch"></v-text-field>
						</template>
					</card-heading>
					<template v-if="!secLoad">
						<v-card-text v-if="searchedCasinos.length > 0">
							<v-card
								class="elevation-1 ma-0"
								:class="{ 'mb-4': index < filteredCasinos.length - 1 }"
								v-for="(item, index) in filteredCasinos"
								:key="item.website">
								<v-card-title
									class="secondary d-flex align-center justify-space-between py-2 body-1">
									{{ item.website.toUpperCase() }}
									<v-chip :class="selectedGroup ? 'success' : 'primary'">
										ID: {{ item.casino_id }}
									</v-chip>
								</v-card-title>
								<v-card-text class="py-1">
									<div>Country: {{ item.country }}</div>
								</v-card-text>
							</v-card>
						</v-card-text>
						<v-card-text v-else-if="userCasinos.length == 0">
							No casinos found.
						</v-card-text>
						<v-card-actions
							style="
								width: 100%;
								position: absolute;
								bottom: 0;
								left: 50%;
								transform: translate(-50%, 0);
							">
							<LoganPagination
								:color="editColor"
								v-model="casinoPagination.page"
								v-if="pageCount > 1"
								:pageCount="pageCount"></LoganPagination>
						</v-card-actions>
					</template>
					<v-card-text v-else>{{ isLoadingText }}</v-card-text>
				</v-card>
			</v-col>

			<v-col
				cols="12"
				md="8">
				<v-card
					ref="treeview"
					class="v-card--material true pa-3 px-5 py-3">
					<card-heading
						color="secondary"
						title="Sections"></card-heading>

					<v-card-text class="mt-1">
						<UserEditSections
							:loading="secLoad"
							:color="editColor"
							:usersPerms="sectionId"></UserEditSections>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import UserEditSections from "../../components/users/UserEditSections.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import { inflate } from "pako";
import { mapGetters, mapActions } from "vuex";
import permissions from "../../mixins/permissions";
import LoganPagination from "../../components/shared/LoganPagination.vue";

export default {
	props: {
		user_id: {
			type: String,
		},
	},
	data() {
		return {
			casinoPagination: {
				page: 1,
				pageSize: 8,
			},
			turn: 0,
			isLoadingText: "Loading personal data ...",
			loading: false,
			secLoad: false,
			adminUser: false,
			selectTest: [],
			allCasinos: [],
			casinoSearch_: "",
			selectedGroup: undefined,
			treeHeight: 400,
		};
	},
	mixins: [permissions],
	components: {
		CardHeading,
		UserEditSections,
		LoganPagination,
	},
	computed: {
		...mapGetters("permissions", {
			detail: "detail",
		}),
		...mapGetters([
			"userId",
			"userDetail",
			"sectionList",
			"casinoList",
			"userPerm",
			"user",
		]),
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
		}),
		casinoSearch: {
			get() {
				return this.casinoSearch_;
			},
			set(e) {
				this.casinoSearch_ = e;
				this.casinoPagination.page = 1;
			},
		},
		pageCount() {
			if (this.searchedCasinos.length <= this.casinoPagination.pageSize + 1) {
				return 1;
			}
			return Math.ceil(
				this.searchedCasinos.length / this.casinoPagination.pageSize
			);
		},
		searchedCasinos() {
			return this.userCasinos.filter(
				({ casino_id: cid, website: web, country }) =>
					`${cid}${web}${country}`
						.toLowerCase()
						.includes(this.casinoSearch?.toLowerCase() ?? "")
			);
		},
		filteredCasinos() {
			if (this.searchedCasinos.length <= this.casinoPagination.pageSize + 1) {
				return [...this.searchedCasinos].sort(
					(a, b) => a.casino_id - b.casino_id
				);
			} else {
				return [...this.searchedCasinos]
					.sort((a, b) => a.casino_id - b.casino_id)
					.slice(
						this.casinoPagination.pageSize * (this.casinoPagination.page - 1),
						this.casinoPagination.pageSize * this.casinoPagination.page
					);
			}
		},
		hasAnyManagement() {
			return this._usrFlagsSome({
				key: ["management.users.list", "management.users.detail"],
				val:
					this.permissions.READ |
					this.permissions.EDIT |
					this.permissions.CREATE |
					this.permissions.DELETE,
			});
		},
		userName() {
			return (
				this._userDetail.data?.first_name +
				" " +
				this._userDetail.data?.last_name
			);
		},
		user_ID() {
			return this.user_id;
		},
		userCasinos() {
			if (this.secLoad) return [];
			if (this.selectedGroup)
				return (
					this.casinoList?.data?.filter((casino) =>
						this.detail?.casinos?.includes(casino.casino_id)
					) ?? []
				);
			return (
				this.casinoList?.data?.filter((casino) =>
					this.userPerms?.casinos?.includes(casino.casino_id)
				) ?? []
			);
		},
		_userDetail() {
			if (this.userDetail.data && this.hasAnyManagement) return this.userDetail;
			return this.user;
		},
		sectionId() {
			if (!this.userPerms) return;
			if (this.selectedGroup) return this.detail?.sections;
			const res = this.userPerms.sections;
			return res;
		},
		userCasinoWebsites() {
			return this.userCasinos?.map((casino) => casino.website);
		},
		userCasinoIds() {
			return this.userCasinos?.map((casino) => casino.casino_id);
		},

		userPerms() {
			return (
				JSON.parse(
					new TextDecoder().decode(
						inflate(Buffer.from(this._userDetail?.data?.permissions, "base64"))
					)
				)?.logan ?? {}
			);
		},
		editColor() {
			if (this.selectedGroup) return "success";
			return "primary";
		},
		computedTreeHeight() {
			return this.$vuetify.breakpoint.smAndDown
				? this.treeHeight / 2 + 50
				: this.treeHeight;
		},
	},
	methods: {
		...mapActions("permissions", {
			loadDetail: "loadDetail",
		}),
		...mapActions(["getUserDetail", "setBreadCrumbs", "getCasinoList"]),
		r() {
			this.turn++;
		},
		async handleChipSelect(e) {
			if (e) {
				this.secLoad = true;
				await this.loadDetail(e);
				this.secLoad = false;
			}
		},
		async loadData() {
			if (!this.hasAnyManagement) return;
			await this.getUserDetail(this.user_id);
		},
	},
	watch: {
		async user_id() {
			await this.loadData();
		},
	},
	async created() {
		this.loading = true;
		this.secLoad = true;
		await this.loadData();
		this.getCasinoList().then(() => {
			this.allCasinos = this.casinoList?.data;
		});
		this.adminUser = this._otherUsrFlagsSome(this.sectionId || [], {
			key: [
				"management.users.list",
				"management.users.detail",
				"management.groups.list",
				"management.groups.detail",
			],
			val:
				this.permissions.READ |
				this.permissions.EDIT |
				this.permissions.CREATE |
				this.permissions.DELETE,
		});
		this.loading = false;
		this.secLoad = false;
	},
	updated() {
		this.treeHeight = this.$refs?.treeview?.$el?.offsetHeight;
	},
};
</script>

<style scoped>
.float {
	align-items: center;
	align-self: center;
	max-height: 22px;
}
</style>
