<template>
	<v-container style="user-select: none">
		<div class="d-flex flex-row justify-center align-center">
			<v-btn
				v-if="pageCount > pagesToShow"
				:disabled="selectedPage === 1"
				@click="$emit('selected', 1)"
				text
				small
				icon>
				<v-icon color="kajot-disabled">mdi-page-first</v-icon>
			</v-btn>
			<v-btn
				v-if="pageCount > 1"
				:disabled="selectedPage === 1"
				@click="$emit('selected', selectedPage - 1)"
				text
				small
				icon>
				<v-icon color="kajot-disabled">mdi-chevron-left</v-icon>
			</v-btn>
			<div class="mx-1">
				<v-hover
					v-for="page of shownPages"
					:key="`${page}-clicker`"
					v-slot="{ hover }">
					<v-avatar
						@click="$emit('selected', page)"
						size="30"
						v-ripple
						:class="{ 'mr-2': page !== pageCount }"
						style="cursor: pointer"
						:color="`${
							hover
								? color
								: page === selectedPage
								? color
								: 'secondary lighten-1'
						}`">
						{{ page }}
					</v-avatar>
				</v-hover>
			</div>
			<v-btn
				:disabled="selectedPage === pageCount"
				v-if="pageCount > 1"
				@click="$emit('selected', selectedPage + 1)"
				text
				small
				icon>
				<v-icon color="kajot-disabled">mdi-chevron-right</v-icon>
			</v-btn>
			<v-btn
				:disabled="selectedPage === pageCount"
				v-if="pageCount > pagesToShow"
				@click="$emit('selected', pageCount)"
				text
				small
				icon>
				<v-icon color="kajot-disabled">mdi-page-last</v-icon>
			</v-btn>
		</div>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			pagesToShow: 5,
		};
	},
	mixins: [],
	props: {
		pageCount: {
			type: Number,
			default: 1,
		},
		selectedPage: {
			type: Number,
			default: 1,
		},
		color: {
			type: String,
			default: "primary",
		},
	},
	methods: {},
	computed: {
		shownPages() {
			const visiblePages = [];
			const middleIndex = Math.floor(this.pagesToShow / 2);

			let startPage = Math.max(1, this.selectedPage - middleIndex);
			const endPage = Math.min(
				startPage + this.pagesToShow - 1,
				this.pageCount
			);

			if (endPage - startPage + 1 < this.pagesToShow) {
				startPage = Math.max(1, endPage - this.pagesToShow + 1);
			}

			for (let i = startPage; i <= endPage; i++) {
				visiblePages.push(i);
			}

			return visiblePages;
		},
	},
	model: {
		event: "selected",
		prop: "selectedPage",
	},
};
</script>
