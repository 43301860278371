var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "userDetail", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material true pa-3" },
                [
                  _c("card-heading", {
                    attrs: {
                      color: "secondary",
                      loading: _vm.loading,
                      loadingTitle: "Loading...",
                      title: _vm.userName,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.r()
                                  },
                                },
                              },
                              [
                                _vm.adminUser
                                  ? _c(
                                      "v-icon",
                                      {
                                        key: _vm.adminUser,
                                        style: `transform: rotate(${
                                          Math.floor(_vm.turn / 5) * 360
                                        }deg)`,
                                        attrs: { color: "warning", large: "" },
                                      },
                                      [_vm._v(" mdi-crown ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [_c("div", { staticClass: "d-flex" })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-body-1" },
                            [_vm._v(" Email: ")]
                          ),
                          !_vm.loading
                            ? _c(
                                "v-list-item-content",
                                { staticClass: "text-body-2 align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._userDetail?.data?.email) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "v-list-item-content",
                                { staticClass: "text-body-2 align-end" },
                                [_vm._v(" Loading email... ")]
                              ),
                        ],
                        1
                      ),
                      _vm.hasAnyManagement
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "text-body-1" },
                                [_vm._v(" Groups: ")]
                              ),
                              !_vm.loading
                                ? _c(
                                    "v-list-item-content",
                                    { staticClass: "text-body-2 align-end" },
                                    [
                                      _c(
                                        "v-chip-group",
                                        {
                                          attrs: { "active-class": "success" },
                                          on: { change: _vm.handleChipSelect },
                                          model: {
                                            value: _vm.selectedGroup,
                                            callback: function ($$v) {
                                              _vm.selectedGroup = $$v
                                            },
                                            expression: "selectedGroup",
                                          },
                                        },
                                        _vm._l(
                                          _vm._userDetail.data.groups,
                                          function (item) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: item,
                                                staticStyle: {
                                                  "max-width": "fit-content",
                                                },
                                                attrs: { value: item },
                                              },
                                              [_vm._v(" " + _vm._s(item) + " ")]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list-item-content",
                                    { staticClass: "text-body-2 align-end" },
                                    [_vm._v(" Loading groups... ")]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "v-card--material pa-3 px-5 py-3 d-flex flex-column",
                  attrs: { height: _vm.computedTreeHeight },
                },
                [
                  _c("card-heading", {
                    attrs: { color: "secondary", title: "Casinos" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function ({ title }) {
                          return [_vm._v(_vm._s(title))]
                        },
                      },
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("v-spacer"),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                light: !_vm.$vuetify.theme.dark,
                                "hide-details": "",
                                label: "Search",
                                clearable: "",
                              },
                              model: {
                                value: _vm.casinoSearch,
                                callback: function ($$v) {
                                  _vm.casinoSearch = $$v
                                },
                                expression: "casinoSearch",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  !_vm.secLoad
                    ? [
                        _vm.searchedCasinos.length > 0
                          ? _c(
                              "v-card-text",
                              _vm._l(
                                _vm.filteredCasinos,
                                function (item, index) {
                                  return _c(
                                    "v-card",
                                    {
                                      key: item.website,
                                      staticClass: "elevation-1 ma-0",
                                      class: {
                                        "mb-4":
                                          index <
                                          _vm.filteredCasinos.length - 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "secondary d-flex align-center justify-space-between py-2 body-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.website.toUpperCase()
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              class: _vm.selectedGroup
                                                ? "success"
                                                : "primary",
                                            },
                                            [
                                              _vm._v(
                                                " ID: " +
                                                  _vm._s(item.casino_id) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "py-1" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Country: " + _vm._s(item.country)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm.userCasinos.length == 0
                          ? _c("v-card-text", [_vm._v(" No casinos found. ")])
                          : _vm._e(),
                        _c(
                          "v-card-actions",
                          {
                            staticStyle: {
                              width: "100%",
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              transform: "translate(-50%, 0)",
                            },
                          },
                          [
                            _vm.pageCount > 1
                              ? _c("LoganPagination", {
                                  attrs: {
                                    color: _vm.editColor,
                                    pageCount: _vm.pageCount,
                                  },
                                  model: {
                                    value: _vm.casinoPagination.page,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.casinoPagination,
                                        "page",
                                        $$v
                                      )
                                    },
                                    expression: "casinoPagination.page",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _c("v-card-text", [_vm._v(_vm._s(_vm.isLoadingText))]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                {
                  ref: "treeview",
                  staticClass: "v-card--material true pa-3 px-5 py-3",
                },
                [
                  _c("card-heading", {
                    attrs: { color: "secondary", title: "Sections" },
                  }),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-1" },
                    [
                      _c("UserEditSections", {
                        attrs: {
                          loading: _vm.secLoad,
                          color: _vm.editColor,
                          usersPerms: _vm.sectionId,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }